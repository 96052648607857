import React, { useState, useEffect } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import Dropdown from '../Common/Dropdown.js';
import { withRouter } from 'react-router';
import { ReactComponent as DoneStepIcon } from '../../assets/img/icons/common/doneStep.svg';
// import ComponentNumberInput from './ComponentNumberInput.js';
import PackagingInput from './PackagingInput.js';
// import DynamicPriceInput from './DynamicPriceInput.js';
import {packagingCategory} from '../../utils/packaging-models/category.js';
import {packagingModels} from '../../utils/packaging-models/model.js';
// reactstrap components
import {
    Button,
    Form,
    Row,
    Col,
    Card,
    CardBody,
    Pagination,
    PaginationItem,
    PaginationLink,
    Input,
    CardText,
    Collapse,
    UncontrolledCarousel,
} from 'reactstrap';

import 'react-modern-drawer/dist/index.css';

import client from '../../feathers.js';

const validationSchema = Yup.object().shape({
    code: Yup.string().required('Code is required'),
    name: Yup.string().required('Name is required'),
    applicableSource: Yup.string().required('Source is required!'),
});

const PackagingType = (props) => {
    const { componentId, componentsTypeId, userId, componentTypeName, onSubmit, duplicate } = props;
    const [supportedSource, setSupportedSource] = useState([
        {
            value: 'packaging',
            display: 'SC-packaging'
        }
    ]);
    const [existingComponent, setExistingComponent] = useState();
    const [categoryList, setCategoryList] = useState([]);
    const [modelList, setModelList] = useState([]);
    const [topLevel, setTopLevel] = useState('');
    const [secondayLevel, setSecondayLevel] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 20;

    useEffect(() => {
        setTopLevel(existingComponent?.data?.packaging?.top);
        setSecondayLevel(existingComponent?.data?.packaging?.secondary);
    }, [existingComponent]);

    useEffect(() => {
        let top = packagingCategory.filter(x => x.type === 'top').map(x => {
            x.models = [];
            return x;
        });
        let secondary = packagingCategory.filter(x => x.type === 'secondary');

        top.map(t => {
            secondary.map(s => {
                if(s.super_id === t.id){
                    t.models.push(s);
                }
            });
        });
        setCategoryList(top);
    }, []);

    useEffect(() => {
        if(secondayLevel){
            let filterModels = packagingModels.filter(x => x.sub_id?.includes(secondayLevel));
            setModelList(filterModels.slice(0, itemsPerPage));
            // Calculate total pages
            setTotalPages(Math.ceil(filterModels.length / itemsPerPage));
            setCurrentPage(1);
        }
    }, [secondayLevel]);

    useEffect(async () => {
        if (componentId) {
            await client.authenticate()
                .then(()=>{
                    return client.service('components').get(componentId);
                })
                .then((res) => {
                    setExistingComponent({
                        ...res,
                        code: duplicate ? '' : res.code
                    });
                    if(res?.data?.packaging?.top){
                        setTopLevel(res?.data?.packaging?.top);
                    }
                    if(res?.data?.packaging?.secondary){
                        setSecondayLevel(res?.data?.packaging?.secondary);
                    }
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        props.notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                    }else{
                        props.notificationOpen(true, 'danger', err.message);
                    }
                });
        }
    }, []);
  
    // Handle page change
    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);

            let filterModels = packagingModels.filter(x => x.sub_id?.includes(secondayLevel));
            const startIndex = (page - 1) * itemsPerPage;
            setModelList(filterModels.slice(startIndex, startIndex + itemsPerPage));
        }
    };
  
    // Function to generate pagination buttons with dots
    const generatePagination = () => {
        const pages = [];
  
        if (totalPages <= 7) {
        // Show all page numbers if there are 7 or fewer pages
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
        // Add first page
            pages.push(1);
  
            // Add dots if current page > 4
            if (currentPage > 4) {
                pages.push('...');
            }
  
            // Add middle pages
            const start = Math.max(2, currentPage - 1);
            const end = Math.min(totalPages - 1, currentPage + 1);
  
            for (let i = start; i <= end; i++) {
                pages.push(i);
            }
  
            // Add dots if current page is not near the end
            if (currentPage < totalPages - 3) {
                pages.push('...');
            }
  
            // Add last page
            pages.push(totalPages);
        }
  
        return pages;
    };
  
    const paginationButtons = generatePagination();
  
    const PaginationRender = () => {
        return (
            <Pagination
                className='pagination justify-content-center mb-0'
                listClassName='justify-content-center mb-0'
            >
                <PaginationItem className={(currentPage === 1 && 'disabled')}>
                    <PaginationLink
                        onClick={() => {
                            handlePageChange(currentPage - 1);
                        }} 
                        tabIndex='-1'
                    >
                        <i className='fas fa-angle-left' />
                        <span className='sr-only'>Previous</span>
                    </PaginationLink>
                </PaginationItem>
                {paginationButtons.map((page, index) =>
                    page === '...' ? (
                        <span key={index} style={{ margin: '0 5px' }}>
                            ...
                        </span>
                    ) : (
                        <PaginationItem key={index} className={(currentPage === page? 'active': '')}>
                            <PaginationLink
                                onClick={() => {
                                    handlePageChange(page);
                                }} 
                            >
                                {page}
                            </PaginationLink>
                        </PaginationItem>
                    )
                )}
                <PaginationItem className={(currentPage === totalPages && 'disabled')}>
                    <PaginationLink
                        onClick={() => {
                            handlePageChange(currentPage + 1);
                        }} 
                    >
                        <i className='fas fa-angle-right' />
                        <span className='sr-only'>Next</span>
                    </PaginationLink>
                </PaginationItem>
            </Pagination>
        );
    };

    return (  
        <>
            <Formik
                initialValues={{
                    _id: (existingComponent && !duplicate) ? existingComponent._id : undefined,
                    userId,
                    applicableSource: (existingComponent) ? existingComponent.applicableSource : 'product',
                    code: (existingComponent) ? existingComponent.code : '',
                    name: (existingComponent) ? existingComponent.name : '',
                    description: (existingComponent) ? existingComponent.description : '',
                    dynamicPriceId: (existingComponent) ? existingComponent.dynamicPriceId : undefined,
                    defaultSize: (existingComponent) ? existingComponent.defaultSize : 'mm',
                    componentsTypeId,
                    componentTypeName,
                    status: (existingComponent) ? existingComponent.status : 1,
                    data: (existingComponent) ? existingComponent.data : {
                        packaging: {
                            type: '0',
                            top: '',
                            secondary: '',
                            model: '',
                            models: [],
                        },
                    },
                }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                enableReinitialize={true}
            >
                {propsChild => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        handleSubmit,
                    } = propsChild;

                    const handlePPchange = (name, event) => {
                        const massagedPP = Math.ceil(event.target.value / 4) * 4;
                        setFieldValue(name, massagedPP);
                    };

                    return (
                        <Form role='form' onSubmit={handleSubmit}>
                            <div className='modal-body p-0'>
                                <Card className='shadow mb-4'>
                                    <CardBody className='rounded'>
                                        <div className='px-3 mb-4 border rounded'>
                                            <Row className='p-3 px-4'>
                                                <h4 className='m-0'>{componentTypeName} Information</h4>
                                            </Row>
                                            <Row className='bg-secondary p-2 py-4 rounded-bottom'>
                                                <Col md='2'>
                                                    <h5 className='mb-1'>Applicable for:</h5>
                                                    <Dropdown
                                                        setFieldValue={setFieldValue}
                                                        name={'applicableSource'}
                                                        disabled={(existingComponent)}
                                                        values={[
                                                            ...supportedSource
                                                        ]}
                                                        width={'100%'}
                                                    />
                                                    {errors.applicableSource && touched.applicableSource ? (
                                                        <small className='text-danger xs'>{errors.applicableSource}</small>
                                                    ) : null}
                                                </Col>
                                                <Col md='2'>
                                                    <h5 className='mb-1'>Code</h5>
                                                    <Field 
                                                        className='form-control form-control-sm form-control-alternative'
                                                        placeholder='Code'
                                                        type='text'
                                                        name={'code'}
                                                        maxlength='8'
                                                        normalize={value => (value || '').toUpperCase()}
                                                    />
                                                    {errors.code && touched.code ? (
                                                        <small className='text-danger xs'>{errors.code}</small>
                                                    ) : null}
                                                </Col>
                                                <Col md='4'>
                                                    <h5 className='mb-1'>Name</h5>
                                                    <Field 
                                                        className='form-control form-control-sm form-control-alternative'
                                                        placeholder='Name'
                                                        type='text'
                                                        name={'name'}
                                                        maxlength='40'
                                                    />
                                                    {errors.name && touched.name ? (
                                                        <small className='text-danger xs'>{errors.name}</small>
                                                    ) : null}
                                                </Col>
                                                <Col md='4'>
                                                    <h5 className='mb-1'>Description</h5>
                                                    <Field 
                                                        className='form-control form-control-sm form-control-alternative'
                                                        placeholder='Description'
                                                        type='text'
                                                        name={'description'}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className='px-3 mb-1 border rounded'>
                                            <Row className='p-3 px-4'>
                                                <h4 className='m-0'>{componentTypeName} Properties</h4>
                                            </Row>
                                            <Row className='bg-white px-4 py-3 rounded-bottom'>
                                                {(values?.data?.packaging.top && values?.data?.packaging.secondary && values?.data?.packaging?.model) ? (
                                                    <DoneStepIcon className='mr-4'/>
                                                ) : (
                                                    <div className='mr-4 d-flex align-items-center justify-content-center'
                                                        style={{ width: 32, height: 32, background: 'white', borderRadius: '50%', border: '1px solid #166BAE', color: '#166BAE' }}>
                                                        1
                                                    </div>
                                                )}
                                                <Col className='p-0 m-0'>
                                                    <Row className='m-0 d-flex align-items-center mb-1'>
                                                        <Col md='4' className='pl-0'>
                                                            <p className='mr-1 mb-0 font-weight-normal'>Packaging</p>
                                                        </Col>
                                                        <Col md='8'>
                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                <p className='mb-0 text-xs font-weight-normal'>Selected ({values?.data?.packaging?.models?.length || 0}/20)</p>
                                                                {(values?.data?.packaging?.top && values?.data?.packaging?.secondary && values?.data?.packaging?.models?.length > 0) &&
                                                                <Button
                                                                    color='secondary'
                                                                    data-dismiss='modal'
                                                                    type='button'
                                                                    onClick={() => {
                                                                        setFieldValue('data.packaging.models', []);
                                                                        setFieldValue('data.packaging.top', '');
                                                                        setFieldValue('data.packaging.secondary', '');
                                                                        setTopLevel('');
                                                                        setSecondayLevel('');
                                                                    }}
                                                                >
                                                                    Clear
                                                                </Button>}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className='m-0'>
                                                        <Col lg='3' className='pl-0'>
                                                            {
                                                                categoryList.map((x) => {
                                                                    return (
                                                                        <div className='pb-2' key={x.id} onClick={() => {
                                                                            if(values?.data?.packaging?.models?.length === 0){
                                                                                setTopLevel(x.id?.toString());
                                                                                setFieldValue('data.packaging.top', x.id.toString());
                                                                            }
                                                                        }}>
                                                                            <div className={`cursor-pointer px-2 ${topLevel === x.id?.toString() && 'p-2 card shadow'}`}>
                                                                                <p className={`p-0 m-0 text-sm font-weight-bold ${topLevel === x.id?.toString() && 'font-weight-bolder' }`}>
                                                                                    {x.name}
                                                                                </p>
                                                                            </div>
                                                                            {
                                                                                topLevel === x.id?.toString() && (
                                                                                    <div className='ml-4'>
                                                                                        {
                                                                                            x.models.map(k =>{
                                                                                                return (
                                                                                                    <div 
                                                                                                        key={k.id} 
                                                                                                        className={`cursor-pointer mb-1 ${secondayLevel === k.id.toString() && 'p-2 card shadow'}`} 
                                                                                                        onClick={() => {
                                                                                                            if(values?.data?.packaging?.models?.length === 0){
                                                                                                                setSecondayLevel(k.id.toString());
                                                                                                                setFieldValue('data.packaging.secondary', k.id.toString());
                                                                                                            }
                                                                                                        }}>
                                                                                                        <p className={`mb-0 text-sm font-weight-normal ${secondayLevel === k.id.toString() && 'font-weight-bold'}`}>{k.id} - {k.name}</p>
                                                                                                    </div>
                                                                                                );
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                        </Col>
                                                        <Col lg='9'>
                                                            {
                                                                secondayLevel &&
                                                                <>
                                                                    <div className='m-0 mb-4 packaging-component-wrapper'>
                                                                        {
                                                                            modelList.map((x, i) => {
                                                                                let mode_image = `https:${x.model_image}`;
                                                                                let knife_image = `https:${x.knife_image}`;
                                                                                const isChecked = values?.data?.packaging?.models.includes(
                                                                                    x.model.toString()
                                                                                );
                                                                                const items = [{
                                                                                    altText: x.model,
                                                                                    key: 1,
                                                                                    src: mode_image
                                                                                },
                                                                                {
                                                                                    altText: x.model,
                                                                                    key: 2,
                                                                                    src: knife_image
                                                                                }];
                                                                                  
                                                                                return (
                                                                                    <div 
                                                                                        key={x.model + x.name + i}
                                                                                        className={`relative p-2 cursor-pointer shadow ${values?.data?.packaging?.models?.includes(x.model.toString()) && 'card'}`} 
                                                                                        style={{position: 'relative'}}
                                                                                        // onClick={() => {
                                                                                        //     if(!isChecked){
                                                                                        //         if(values?.data?.packaging?.models?.length <= 19){
                                                                                        //             setFieldValue(
                                                                                        //                 'data.packaging.models',
                                                                                        //                 [
                                                                                        //                     ...values.data.packaging.models,
                                                                                        //                     x.model.toString(),
                                                                                        //                 ]
                                                                                        //             );   
                                                                                        //         }
                                                                                        //     }else{
                                                                                        //         setFieldValue(
                                                                                        //             'data.packaging.models', values?.data?.packaging?.models?.filter(
                                                                                        //                 (id) => id !== x.model.toString()
                                                                                        //             )
                                                                                        //         );
                                                                                        //     }
                                                                                        // }}
                                                                                    >
                                                                                        <input
                                                                                            type='checkbox'
                                                                                            className='form-check-input'
                                                                                            id={`model-${x.model}`}
                                                                                            checked={
                                                                                                values.data.packaging.models.includes(
                                                                                                    x.model.toString()
                                                                                                )
                                                                                            }
                                                                                            style={{
                                                                                                zIndex: 2,
                                                                                                position: 'absolute',
                                                                                                right: 8,
                                                                                                top: -7
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                if(!isChecked){
                                                                                                    if(values?.data?.packaging?.models?.length <= 19){
                                                                                                        setFieldValue(
                                                                                                            'data.packaging.models',
                                                                                                            [
                                                                                                                ...values.data.packaging.models,
                                                                                                                x.model.toString(),
                                                                                                            ]
                                                                                                        );   
                                                                                                    }
                                                                                                }else{
                                                                                                    setFieldValue(
                                                                                                        'data.packaging.models', values?.data?.packaging?.models?.filter(
                                                                                                            (id) => id !== x.model.toString()
                                                                                                        )
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                        <div className='mb-2 rounded overflow-hidden packaging-img-wrapper'>
                                                                                            <UncontrolledCarousel
                                                                                                interval={false}
                                                                                                items={items}
                                                                                                dark={false}
                                                                                            />
                                                                                            {/* <img
                                                                                                alt=''
                                                                                                src={mode_image}
                                                                                                className='w-100'
                                                                                            /> */}
                                                                                            {/* <img
                                                                                                alt='...'
                                                                                                src={
                                                                                                    require('../../assets/img/theme/team-1-800x800.jpg')
                                                                                                }
                                                                                                className='w-100'
                                                                                            /> */}
                                                                                        </div>
                                                                                        {/* <div 
                                                                                            className='rounded'
                                                                                            style={{
                                                                                                position: 'absolute',
                                                                                                right: '5%',
                                                                                                top: '5%',
                                                                                                width: '30%',
                                                                                                background: 'white'
                                                                                            }}
                                                                                        >
                                                                                            <img
                                                                                                alt=''
                                                                                                src={knife_image}
                                                                                                className='w-100'
                                                                                            />
                                                                                        </div> */}
                                                                                        {/* <p className='small' style={{lineHeight: 1.2}}>{x.name}</p> */}
                                                                                        <p className='small' style={{lineHeight: 1.2}}>{x.model}</p>
                                                                                    </div>
                                                                                );
                                                                            })
                                                                        }         
                                                                    </div>
                                                                    <nav aria-label='...'>
                                                                        <PaginationRender />
                                                                    </nav>
                                                                </>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            {/* <Row className='bg-light-grey px-4 py-3 rounded-bottom d-flex align-items-center'>
                                                {(values.dynamicPriceId) ? (
                                                    <DoneStepIcon className='mr-4'/>
                                                ) : (
                                                    <div className='mr-4 d-flex align-items-center justify-content-center'
                                                        style={{ width: 32, height: 32, background: 'white', borderRadius: '50%', border: '1px solid #166BAE', color: '#166BAE' }}>
                                                        2
                                                    </div>
                                                )}
                                                <div>
                                                    <Row className='m-0 d-flex align-items-center'>
                                                        <small className='mr-1 mb-1'>Dynamic Price (optional) </small>
                                                    </Row>
                                                    <DynamicPriceInput
                                                        setFieldValue={setFieldValue}
                                                        dynamicPriceData={dynamicPriceData}
                                                        location={'dynamicPriceId'}
                                                    />
                                                </div>
                                            </Row> */}
                                        </div>
                                        <div className='modal-footer'>
                                            <Button
                                                color='secondary'
                                                data-dismiss='modal'
                                                type='button'
                                                onClick={() => {
                                                    props.history.push(`/${props.role}/components`);
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                color='secondary' 
                                                type='submit'
                                                onClick={() => setFieldValue('status', 0)}
                                            >
                                                Save as Draft
                                            </Button>
                                            <Button
                                                color='primary' 
                                                type='submit'
                                                onClick={() => setFieldValue('status', 1)}
                                            >
                                                Save as Active
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};
 
export default withRouter(PackagingType);